footer {
    background: var(--color-primary);
    padding-top: 4rem;
    padding-bottom: 0.5rem;
    box-shadow: 0 -1rem 1.5rem hsla(var(--primary-hue), 70%, 40%, 20%);
    transition: var(--transition);
}

footer .nav__menu {
    justify-content: center;
}

.footer__socials {
    width:fit-content;
    display:flex;
    gap: 1.5rem;
    margin: 2rem auto 4rem;
}

.footer__socials a{
    background: var(--color-black);
    color: var(--color-white);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
}

.footer__socials a:hover{
    opacity: 0.7rem;
    transform: translateY(-0.5rem);
}

